export const join = (input: string[], separator: string): string => {
    return input.join(separator);
};

export const split = (input: string): string[] => {
    return input.split('\n');
}

export const mapPerLine = (lambda: any, input: string[]): string[] => {
    return input.map(lambda);
}