import React, {useState} from "react";
import ToolHeader from "components/ToolHeader";
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from "layouts/ToolLayout";

export default function RemoveDuplicateLines() {
    const [content, setContent] = useState('');
    const [output, setOutput] = useState('');
    const [isReverseSorting, setIsReverseSorting] = useState(false);

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        const inputArray = content.split('\n');
        const result = inputArray.filter((line, index, lines) => {
            return lines.indexOf(line) === index;
        });

        if (isReverseSorting) {
            result.reverse();
        } else {
            result.sort();
        }


        setOutput(result.join('\n'));
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Remove duplicate lines - Text Beautify"} />
            <ToolHeader
                header={"Remove duplicate lines"}
                description={"A free online tool to remove duplicate lines. Just paste your text in the below box and click remove duplicate lines button."}
            />
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Paste your text here</label>
                        <textarea
                            className="form-control"
                            rows={9}
                            value={content}
                            onChange={e => setContent(e.target.value)}
                        />
                    </div>

                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isReverseSorting}
                                onChange={() => setIsReverseSorting((prev) => !prev)}
                            />
                            <label className="form-check-label">
                                Reverse Sorting
                            </label>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary">Remove Duplicate Lines</button>
                </form>
            </div>

            <div className="container">
                <hr />
            </div>

            <div className="container">
                <h1>No duplicate lines</h1>
                <textarea
                    className="form-control"
                    rows={9}
                    value={output}
                />
            </div>
        </ToolLayout>
    );
}
