import React from "react";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';
import LeftRightPanel from "layouts/LeftRightPanel";
import {search} from "services/TransformService";

export default function JoinText() {
    const computeOutput = (input: string): string => {
        return search(input, '\n', '');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Join Text - Text Beautify"} />

            <ToolHeader
                header={"Join Text"}
                description={"Join multiple lines into one line."}
            />

            <LeftRightPanel
                computeOutput={computeOutput}
            />
        </ToolLayout>
    );
}