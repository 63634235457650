import {useEffect} from "react";

type Props = {
    title: string;
};

export default function DocumentTitle({title}: Props) {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <></>
    );
}