import React, { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

export default function ToolLayout({ children }: Props) {
    return (
        <div className={"my-3"}>
            { children }
        </div>
    );
}