import React, {useState} from "react";
import { password } from 'services/GenerateService';
import ToolHeader from "components/ToolHeader";
import DocumentTitle from "components/DocumentTitle";
import ToolLayout from "layouts/ToolLayout";

export default function GeneratePassword() {
    const defaultPasswordDictionary = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const defaultPasswordLength = 12;

    const [content, setContent] = useState(password(defaultPasswordDictionary, defaultPasswordLength));

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        setContent(password(defaultPasswordDictionary, defaultPasswordLength));
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(content).then(() => {
        });
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Password Generator - Text Beautify"} />
            <ToolHeader
                header={"Generate Password"}
                description={"A free online tool to generate your password for different software. All generated passwords will be not saved."}
            />

            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3">
                        <input type={'text'} value={content} className={'form-control'} />
                        <button className="btn btn-outline-secondary" type="button" onClick={handleCopy}>Copy</button>
                    </div>

                    <button type="submit" className="btn btn-primary">Generate</button>
                </form>
            </div>
        </ToolLayout>
    );
}