import React, {useState} from "react";

type Props = {
    computeOutput: Function;
    defaultOptions?: Object;
    optionsPanel?: Function;
};

export default function LeftRightPanel({ computeOutput, defaultOptions, optionsPanel }: Props) {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');
    const [options, setOptions] = useState(defaultOptions);

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            setInput(text);
        });
    };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
        });
    };

    const handleOptionChanged = (options: any) => {
        setOptions(options);
        setOutput(computeOutput(input, options));
    };

    return (
        <div className="container">
            <div className="row">
                <div className={"col"}>
                    <div className={"mb-3"}>
                        <textarea
                            className="form-control"
                            rows={9}
                            value={input}
                            onChange={(e) => {
                                setInput(e.target.value);
                                setOutput(computeOutput(e.target.value, options));
                            }}
                        />
                    </div>

                    <button className="btn btn-outline-secondary" type="button" onClick={handlePaste}>Paste</button>

                    { optionsPanel ? optionsPanel(options, handleOptionChanged) : <></> }
                </div>
                <div className={"col"}>
                    <div className={"mb-3"}>
                        <textarea
                            className="form-control"
                            rows={9}
                            value={output}
                            disabled={true}
                        />
                    </div>

                    <button className="btn btn-outline-secondary" type="button" onClick={() => handleCopy(output)}>Copy</button>
                </div>
            </div>
        </div>
    );
}