export const password = (passwordDictionary: string, passwordLength: number): string => {
    let password = '';

    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * passwordDictionary.length);
        password += passwordDictionary.substring(randomNumber, randomNumber +1);
    }

    return password;
};

export const randomPickItem = (items: string[]): string => {
    return '';
};

export const firstName = (): string => {
    return '';
};

export const lastName = (): string => {
    return '';
};

export const integer = (): number => {
    return 0;
};

export const primeNumber = (): number => {
    return 3;
};