import React, {useState} from "react";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';

export default function GenerateNumberSequence() {
    let range = [];
    for (let i = 1; i <= 10; i++) {
        range.push(i);
    }
    const [content, setContent] = useState(range.join('\n'));

    return (
        <ToolLayout>
            <DocumentTitle title={"Generate Number Sequence - Text Beautify"} />
            <ToolHeader
                header={"Generate Number Sequence"}
                description={"A free online tool to generate number sequence."}
            />

            <div className="container">
                <form>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Paste your text here</label>
                        <textarea
                            className="form-control"
                            rows={9}
                            value={content}
                        />
                    </div>
                </form>
            </div>
        </ToolLayout>
    );
}