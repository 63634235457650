import React, {useState} from "react";
import ToolHeader from "components/ToolHeader";
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from "layouts/ToolLayout";
import moment from "moment";
import {useMatches} from "react-router-dom";

type OutputProps = {
    hours: number;
    minutes: number;
} | null;

export default function HoursCalculator() {
    const matches = useMatches();
    const match: any = matches.find(match => Boolean(match.handle));

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [output, setOutput] = useState<OutputProps>(null);

    const computeOutput = (_startTime: string, _endTime: string): OutputProps => {
        if (!_startTime) {
            return null;
        }
        if (!_endTime) {
            return null;
        }
        const startMoment = moment(_startTime, 'H:m');
        const endMoment = moment(_endTime, 'H:m');
        const duration = moment.duration(endMoment.diff(startMoment));
        return {
            hours: duration.hours(),
            minutes: duration.minutes(),
        };
    };

    return (
        <ToolLayout>
            <DocumentTitle title={match?.handle?.toolName + " - Text Beautify"} />
            <ToolHeader
                header={match?.handle?.toolName}
                description={match?.handle?.toolDescription}
            />
            <div className="container">
                <div className="row">
                    <div className={"col"}>
                        <div className="row mb-3">
                            <label className="col-sm-2 col-form-label">Start:</label>
                            <div className="col-sm-10">
                                <input type={"time"} className="form-control" value={startTime}
                                   onChange={e => {
                                       setStartTime(e.target.value);
                                       setOutput(computeOutput(e.target.value, endTime));
                                   }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-sm-2 col-form-label">End:</label>
                            <div className="col-sm-10">
                                <input type={"time"} className="form-control" value={endTime}
                                   onChange={e => {
                                       setEndTime(e.target.value);
                                       setOutput(computeOutput(startTime, e.target.value));
                                   }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        {
                            output
                            ?
                                <div>The time between { startTime } and { endTime } is: {output.hours} hours and {output.minutes} minutes.</div>
                            :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </ToolLayout>
    );
}
