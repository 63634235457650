import React from "react";
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from "layouts/ToolLayout";

export default function AboutUs () {
    return (
        <ToolLayout>
            <DocumentTitle title={"About Us - Text Beautify"} />

            <div className={"container"}>
                <h1>About Us</h1>
                <p>We aim to provide free and online tools for everyone.</p>
            </div>
        </ToolLayout>
    );
}