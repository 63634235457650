import React from "react";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';
import LeftRightPanel from "layouts/LeftRightPanel";
import { split, join } from "services/CollectionService";
import {useMatches} from "react-router-dom";

export default function TrimText() {
    const matches = useMatches();
    const match: any = matches.find(match => Boolean(match.handle));

    const computeOutput = (input: string): string => {
        const result = split(input).map(item => item.trim());
        return join(result, '\n');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={match?.handle?.toolName + " - Text Beautify"} />

            <ToolHeader
                header={match?.handle?.toolName}
                description={match?.handle?.toolDescription}
            />

            <LeftRightPanel
                computeOutput={computeOutput}
            />
        </ToolLayout>
    );
}