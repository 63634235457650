import React from "react";
import { Link } from "react-router-dom";
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from "layouts/ToolLayout";
import { toolRoutes } from "index";

export default function Index() {
    return (
        <ToolLayout>
            <DocumentTitle title={"Text Beautify - Free online text tools"} />

            <div className={"container"}>
                <h1>Online Free Text Tools</h1>
                <p>We offer useful text utilities for your daily jobs. All tools are free to use and do not need to download.</p>
            </div>

            <div className={"container"}>
                <div className="row">
                    {
                        toolRoutes.map((toolRoute) => (
                            <div key={toolRoute.path} className="col-3">
                                <Link to={'/' + toolRoute.path}>{toolRoute.handle.toolName}</Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </ToolLayout>
    );
}
