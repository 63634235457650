import React from "react";

type Props = {
    header: string | undefined | null;
    description: string | undefined | null;
};

export default function ToolHeader({header, description}: Props) {
    return (
        <div className="container">
            <h1>{ header }</h1>
            <p>{ description }</p>
        </div>
    );
}