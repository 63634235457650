import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Root from 'routes/root';
import Index from 'routes/index';
import GenerateNumberSequence from 'routes/generate-number-sequence';
import ConvertTextToUppercase from 'routes/convert-text-to-uppercase';
import ConvertTextToLowercase from 'routes/convert-text-to-lowercase';
import RemoveDuplicateLines from 'routes/remove-duplicate-lines';
import GeneratePassword from 'routes/generate-password';
import ConvertTextToMorseCode from 'routes/convert-text-to-morse-code';
import ConvertMorseCodeToText from 'routes/convert-morse-code-to-text';
import TrimText from 'routes/trim-text';
import JoinText from 'routes/join-text';
import PaddingText from 'routes/padding-text';
import TruncateText from 'routes/truncate-text';
import RepeatText from 'routes/repeat-text';
import PrependText from 'routes/prepend-text';
import HoursCalculator from 'routes/calculators/hours-calculator';
import AgeCalculator from 'routes/calculators/age-calculator';
import BmiCalculator from 'routes/calculators/bmi-calculator';
import AboutUs from 'routes/about-us';

export const toolRoutes = [
    {
        path: "tools/trim-text",
        element: <TrimText />,
        handle: {
            toolName: 'Trim Text',
            toolDescription: 'Remove white space on left and right hand side.',
        },
    },
    {
        path: "tools/remove-duplicate-lines",
        element: <RemoveDuplicateLines />,
        handle: {
            toolName: 'Remove duplicate lines',
        },
    },
    {
        path: "tools/generate-number-sequence",
        element: <GenerateNumberSequence />,
        handle: {
            toolName: 'Generate Number Sequence',
        },
    },
    {
        path: "tools/convert-text-to-uppercase",
        element: <ConvertTextToUppercase />,
        handle: {
            toolName: 'Convert text to uppercase',
        },
    },
    {
        path: "tools/convert-text-to-lowercase",
        element: <ConvertTextToLowercase />,
        handle: {
            toolName: 'Convert text to lowercase',
        },
    },
    {
        path: "tools/generate-password",
        element: <GeneratePassword />,
        handle: {
            toolName: 'Password Generator',
        },
    },
    {
        path: "tools/convert-text-to-morse-code",
        element: <ConvertTextToMorseCode />,
        handle: {
            toolName: 'Morse code encoder',
        },
    },
    {
        path: "tools/convert-morse-code-to-text",
        element: <ConvertMorseCodeToText />,
        handle: {
            toolName: 'Morse code decoder',
        },
    },
    {
        path: "tools/join-text",
        element: <JoinText />,
        handle: {
            toolName: 'Join Text',
        },
    },
    {
        path: "tools/padding-text",
        element: <PaddingText />,
        handle: {
            toolName: 'Padding Text',
        },
    },
    {
        path: "tools/truncate-text",
        element: <TruncateText />,
        handle: {
            toolName: 'Truncate Text',
            toolDescription: 'Paste your text in the form to get truncated text.',
        },
    },
    {
        path: "tools/repeat-text",
        element: <RepeatText />,
        handle: {
            toolName: 'Repeat Text',
            toolDescription: 'Easily copy your text multiple times.',
        },
    },
    {
        path: "tools/repeat-text",
        element: <PrependText />,
        handle: {
            toolName: 'Prepend Text',
            toolDescription: 'Prepend a string to text.',
        },
    },
    {
        path: "calculators/hours-calculator",
        element: <HoursCalculator />,
        handle: {
            toolName: 'Hours Calculator',
            toolDescription: 'This calculator can find out the number of hours and minutes between two times.',
        },
    },
    {
        path: "calculators/age-calculator",
        element: <AgeCalculator />,
        handle: {
            toolName: 'Age Calculator',
            toolDescription: 'This calculator can find out the age given date of birth.',
        },
    },
    {
        path: "calculators/bmi-calculator",
        element: <BmiCalculator />,
        handle: {
            toolName: 'Bmi Calculator',
            toolDescription: 'This calculator can find out your bmi.',
        },
    },
];

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Index />,
            },
            {
                path: "about-us",
                element: <AboutUs />,
            },
            ...toolRoutes,
        ],
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
