import {split} from "./CollectionService";

export const upperCase = (input: string): string => {
    return input.toUpperCase();
};

export const lowerCase = (input: string): string => {
    return input.toLowerCase();
};

export const toggleCase = (input: string): string => {
    return input.toUpperCase().split(' ').map((word) => {
        return (word.charAt(0).toLowerCase() + word.slice(1));
    }).join(' ');
};

export const invertCase = (input: string): string => {
    return '';
};

export const titleCase = (input: string): string => {
    return '';
};

export const prepare = (input: string, prependString: string): string => {
    return prependString + input;
};

export const append = (input: string, appendString: string): string => {
    return input + appendString;
}

export const slug = (input: string): string => {
    return '';
};

export const truncate = (input: string, length: number): string => {
    return input.substring(0, length);
};

export const removeHTML = (input: string): string => {
    return '';
};

export const padding = (input: string, maxLength: number, fillString: string): string => {
    return input.padStart(maxLength, fillString);
};

export const morseCodeDictionary: any = {
    "A": ".-",
    "B": "-...",
    "C": "-.-.",
    "D": "-..",
    "E": ".",
    "F": "..-.",
    "G": "--.",
    "H": "....",
    "I": "..",
    "J": ".---",
    "K": "-.-",
    "L": ".-..",
    "M": "--",
    "N": "-.",
    "O": "---",
    "P": ".--.",
    "Q": "--.-",
    "R": ".-.",
    "S": "...",
    "T": "-",
    "U": "..-",
    "V": "...-",
    "W": ".--",
    "X": "-..-",
    "Y": "-.--",
    "Z": "--..",
    "0": "-----",
    "1": ".---",
    "2": "..---",
    "3": "...--",
    "4": "....-",
    "5": ".....",
    "6": "-....",
    "7": "--...",
    "8": "---..",
    "9": "----.",
    ".": ".-.-.-",
    ",": "--..--",
    ":": "---...",
    "+": ".-.-.",
    "-": "-....-",
    "/": "-..-.",
    "'": ".----.",
    "!": "-.-.--",
    "@": ".--.-.",
    "$": "...-..-",
    "&": ".-...",
    "(": "-.--.",
    ")": "-.--.-",
    "?": "..--..",
    "=": "-...-",
    "_": "..--.-",
};

export const textToMorseCode = (input: string, separator: string): string => {
    return input.toUpperCase().split("").map(item => {
        return morseCodeDictionary[item] ? morseCodeDictionary[item] : item;
    }).join(separator);
};

export const morseCodeToText = (input: string, separator: string): string => {
    const textDictionary = Object.fromEntries(Object.entries(morseCodeDictionary).map(([key, value]) => [value, key]));

    return input.split(" ").map(item => {
        return textDictionary[item] ? textDictionary[item] : item;
    }).join(separator);
};

export const search = (input: string, search: string, replace: string): string => {
    return input.replaceAll(search, replace);
};

export const repeat = (input: string, count: number): string => {
    return input.repeat(count);
};

export const stringify = (input: string): string => {
    return JSON.stringify(input);
};

export const reverse = (input: string): string => {
    const split = input.split('');
    const reverse = split.reverse();
    return reverse.join('');
};

export const htmlDecode = (input: string): string => {
    const txt = new DOMParser().parseFromString(input, "text/html");
    if (!txt.documentElement.textContent) {
        return '';
    }
    return txt.documentElement.textContent;
};

export const jsonToText = () => {
};