import React, {useState} from "react";
import ToolHeader from "components/ToolHeader";
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from "layouts/ToolLayout";
import moment from "moment";
import {useMatches} from "react-router-dom";

export default function AgeCalculator() {
    const matches = useMatches();
    const match: any = matches.find(match => Boolean(match.handle));

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [output, setOutput] = useState('');

    const computeOutput = (_dateOfBirth: string): number => {
        const dateOfBirthMoment = moment(_dateOfBirth);
        return moment().diff(dateOfBirthMoment, 'years');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={match?.handle?.toolName + " - Text Beautify"} />
            <ToolHeader
                header={match?.handle?.toolName}
                description={match?.handle?.toolDescription}
            />
            <div className="container">
                <div className="row">
                    <div className={"col"}>
                        <div className="row">
                            <label className="col-sm-3 col-form-label">Date of Birth:</label>
                            <div className="col-sm-9">
                                <input type={"date"} className="form-control" value={dateOfBirth}
                                   onChange={e => {
                                       setDateOfBirth(e.target.value);
                                       setOutput(computeOutput(e.target.value).toString());
                                   }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        {
                            output
                            ?
                                <div>The person is {output} years old.</div>
                            :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </ToolLayout>
    );
}
