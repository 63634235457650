import React from "react";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';
import LeftRightPanel from "layouts/LeftRightPanel";
import {join, split} from "services/CollectionService";
import {upperCase} from "services/TransformService";

export default function ConvertTextToUppercase() {
    const computeOutput = (input: string): string => {
        const inputArray = split(input);
        const result = inputArray.map((item) => {
            return upperCase(item);
        });

        return join(result, '\n');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Convert text to uppercase - Text Beautify"} />
            <ToolHeader
                header={"Convert text to uppercase"}
                description={"A free online tool to convert text to uppercase. Just paste your text in the below box."}
            />
            <LeftRightPanel
                computeOutput={computeOutput}
            />
        </ToolLayout>
    );
}
