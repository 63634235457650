import React from "react";
import ToolHeader from "components/ToolHeader";
import DocumentTitle from "components/DocumentTitle";
import ToolLayout from "layouts/ToolLayout";
import { split, join } from 'services/CollectionService';
import { padding } from 'services/TransformService';
import LeftRightPanel from "layouts/LeftRightPanel";

export default function PaddingText() {
    const computeOutput = (input: string, options: any): string => {
        const inputArray = split(input);
        const result = inputArray.map(item => padding(item, options.length, options.char === '' ? ' ' : options.char));

        return join(result, '\n');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Padding Text - Text Beautify"} />
            <ToolHeader
                header={"Padding Text"}
                description={"Padding your text."}
            />
            <LeftRightPanel
                computeOutput={computeOutput}
                defaultOptions={{
                    length: 10,
                    char: '',
                }}
                optionsPanel={
                    (currentOptions: any, setOptions: any) => (
                        <div className="my-3">
                            <div className="mb-3">
                                <label className="form-label">Length</label>
                                <input
                                    type={"number"}
                                    className="form-control"
                                    value={currentOptions.length}
                                    onChange={e => setOptions({
                                        ...currentOptions,
                                        length: e.target.value,
                                    })}
                                />
                            </div>
                            <div>
                                <label className="form-label">Char</label>
                                <input
                                    type={"text"}
                                    value={currentOptions.char}
                                    className="form-control"
                                    onChange={e => setOptions({
                                        ...currentOptions,
                                        char: e.target.value,
                                    })}
                                />
                            </div>
                        </div>
                    )
                }
            />
        </ToolLayout>
    );
}