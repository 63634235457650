import React from "react";
import {useMatches} from "react-router-dom";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';
import LeftRightPanel from "layouts/LeftRightPanel";
import {join, split} from "services/CollectionService";
import {lowerCase} from "services/TransformService";

export default function ConvertTextToLowercase() {
    const matches = useMatches();
    const match: any = matches.find(match => Boolean(match.handle));

    const computeOutput = (input: string): string => {
        const inputArray = split(input);
        const result = inputArray.map((item) => {
            return lowerCase(item);
        });

        return join(result, '\n');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={match?.handle?.toolName + " - Text Beautify"} />
            <ToolHeader
                header={match?.handle?.toolName}
                description={"A free online tool to convert text to lowercase. Just paste your text in the below box."}
            />
            <LeftRightPanel
                computeOutput={computeOutput}
            />
        </ToolLayout>
    );
}
