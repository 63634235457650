import React from "react";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';
import LeftRightPanel from "layouts/LeftRightPanel";
import {join, split} from "services/CollectionService";
import { textToMorseCode } from 'services/TransformService';

export default function ConvertTextToMorseCode() {
    const computeOutput = (input: string): string => {
        const inputArray = split(input);
        const result = inputArray.map((item) => {
            return textToMorseCode(item, ' ');
        });

        return join(result, '\n');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Morse code encoder - Text Beautify"} />
            <ToolHeader
                header={"Morse code encoder"}
                description={"A free online tool to convert text to morse code."}
            />
            <LeftRightPanel
                computeOutput={computeOutput}
            />
        </ToolLayout>
    );
}
