import React from "react";
import ToolHeader from 'components/ToolHeader';
import DocumentTitle from 'components/DocumentTitle';
import ToolLayout from 'layouts/ToolLayout';
import LeftRightPanel from "layouts/LeftRightPanel";
import {join, split} from "services/CollectionService";
import { morseCodeToText } from 'services/TransformService';

export default function ConvertMorseCodeToText() {
    const computeOutput = (input: string): string => {
        const inputArray = split(input);

        const result = inputArray.map((item) => {
            return morseCodeToText(item, ' ');
        });

        return join(result, '\n');
    };

    return (
        <ToolLayout>
            <DocumentTitle title={"Morse code decoder - Text Beautify"} />
            <ToolHeader
                header={"Morse code decoder"}
                description={"A free online tool to convert morse code to text."}
            />
            <LeftRightPanel
                computeOutput={computeOutput}
            />
        </ToolLayout>
    );
}
